declare global {
    interface Window {
        __theme: string
        __setPreferredTheme(theme: string): void
        __themeListeners: any[]
    }
}

let themeListener: (() => void) | null = null

export function getTheme(): string {
    if (typeof window === 'undefined') {
        return 'undefined'
    }
    return window.__theme
}

export function setPreferredTheme(theme: string): void {
    console.log('<THEME HANDLER> setPreferredTheme() theme: ', theme)
    window.__setPreferredTheme(theme)
    try {
        localStorage.setItem('preferred-theme', theme)
    } catch (e) {
        console.log('<THEME HANDLER> setPreferredTheme() set preferred-theme -> LocalStorage err: ', e)
    }
    document.documentElement.className = theme
    window.__theme = theme
}

export function addThemeListener(listener: () => void): void {
    if (typeof window === 'undefined' || !window.__themeListeners) {
        return
    }
    if (themeListener) {
        removeThemeListener(themeListener)
    }
    themeListener = listener
    window.__themeListeners.push(listener)
}

export function removeThemeListener(listener: () => void): void {
    if (typeof window === 'undefined' || !window.__themeListeners) {
        return
    }
    window.__themeListeners = window.__themeListeners.filter(l => l !== listener)
    if (window.__themeListeners.length === 0) {
        themeListener = null
    }
}

export function ThemeHandler(): string | void {
    function setTheme(theme: string) {
        if (theme === 'dark-theme') {
            document.documentElement.className = 'dark-theme'
        } else {
            document.documentElement.className = 'light-theme'
        }
        window.__theme = theme
    }

    if (typeof window !== 'undefined') {
        window.__setPreferredTheme = function (theme: string) {
            setTheme(theme)
            try {
                localStorage.setItem('preferred-theme', theme)
            } catch (e) {
                console.log('<THEME HANDLER> ThemeHandler() set preferred-theme -> LocalStorage err: ', e)
            }
        }
    }

    var preferredTheme
    try {
        preferredTheme = localStorage.getItem('preferred-theme')
    } catch (e) {
        console.log('<THEME HANDLER> ThemeHandler() get preferred-theme -> LocalStorage err: ', e)
    }

    if (typeof window !== 'undefined') {
        window.__themeListeners = []
        var darkQuery = window.matchMedia('(prefers-color-scheme: dark)')
        darkQuery.addListener(function (e) {
            window.__setPreferredTheme(e.matches ? 'dark-theme' : 'light-theme')
            window.__themeListeners.forEach(function (listener) {
                listener()
            })
        })
        const themeToSet = preferredTheme ? preferredTheme : darkQuery.matches ? 'dark-theme' : 'light-theme'
        console.log('<THEME HANDLER> themeToSet: ', themeToSet)

        setTheme(themeToSet)
        return themeToSet
    }
}
