import React, { createContext, useReducer } from 'react'
import { ThemeHandler } from '../utils'

const getThemeToSet = () => {
    return ThemeHandler()
}

type Action = {
    type: 'TOGGLE_THEME' | '_'
}

type State = {
    isDarkTheme: boolean
    toggleTheme: (action: Action) => void
}

export type GlobalStateContextType = {
    isDarkTheme: boolean
    toggleTheme: (action: Action) => void
}

const initialState: GlobalStateContextType = {
    isDarkTheme: true,
    toggleTheme: ({ type: string }) => null,
}

export const GlobalStateContext = createContext(initialState)
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'TOGGLE_THEME':
            return {
                ...state,
                isDarkTheme: !state.isDarkTheme,
            }
        default:
            return {
                ...state,
            }
    }
}

export const GlobalContextProvider: React.FC<{ children: any }> = ({ children }) => {
    const [state, appDispatch] = useReducer<React.Reducer<State, Action>>(reducer, {
        isDarkTheme: getThemeToSet() === 'dark-theme' ? true : false,
        toggleTheme: (action: Action) => appDispatch({ type: '_' }),
    })
    return (
        <GlobalStateContext.Provider
            value={{ isDarkTheme: state.isDarkTheme, toggleTheme: () => appDispatch({ type: 'TOGGLE_THEME' }) }}
        >
            {children}
        </GlobalStateContext.Provider>
    )
}
