type FeatureFlagsType =
    | {
          isStandByMode: boolean
          showAboutMenuItem: boolean
          showContactMenuItem: boolean
          showCvCTA: boolean
          showHireMeBanner: boolean
      }
    | {
          isStandByMode: boolean
          showAboutMenuItem: boolean
          showContactMenuItem: boolean
          showCvCTA: boolean
          showHireMeBanner: boolean
      }
    | undefined

const ENV: string = process.env.NODE_ENV || 'production'
const standByMode: boolean = !!parseInt(process.env.STAND_BY_MODE || '1', 2)
const showOffMode: boolean = !!parseInt(process.env.SHOW_OFF_MODE || '1', 2)
const jobHuntMode: boolean = !!parseInt(process.env.JOB_HUNT_MODE || '1', 2)

console.log('<FEATURE FLAGS> ENV: ', ENV)
console.log('<FEATURE FLAGS> standByMode: ', standByMode)
console.log('<FEATURE FLAGS> showOffMode: ', showOffMode)
console.log('<FEATURE FLAGS> jobHuntMode: ', jobHuntMode)

export const FeatureFlags: FeatureFlagsType = {
    development: {
        isStandByMode: standByMode,
        showAboutMenuItem: showOffMode,
        showContactMenuItem: false,
        showCvCTA: jobHuntMode,
        showHireMeBanner: false,
    },
    production: {
        isStandByMode: standByMode,
        showAboutMenuItem: true,
        showContactMenuItem: false,
        showCvCTA: true,
        showHireMeBanner: false,
    },
}[ENV]

export default FeatureFlags
