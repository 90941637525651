import React from 'react'
import Navbar from './Navbar'
import { PageProps } from 'gatsby'
import Footer from './Footer'

export default function Layout({ children }: PageProps<JSX.Element>): JSX.Element {
    return (
        <div className="layout">
            <Navbar />
            <main className="content">{children}</main>
            <Footer />
        </div>
    )
}
