import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { GlobalContextProvider } from './src/context/GlobalContextProvider'
import './src/styles/global.scss'
import Layout from './src/components/Layout'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }: any) => {
    return <GlobalContextProvider>{element}</GlobalContextProvider>
}
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }: any) => {
    return <Layout {...props}>{element}</Layout>
}
