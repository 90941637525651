import React from 'react'
import { GatsbyLinkProps, Link as GLink } from 'gatsby'

interface CustomGatsbyLinkProps extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
    active?: boolean
    partiallyActive?: boolean
    activeClassName?: string
}
const Link: React.FC<CustomGatsbyLinkProps> = ({ className, children, ...props }) => (
    <GLink {...props}>{children}</GLink>
)

export default Link
