import React from 'react'
import Modal from 'react-modal'
import ThemeToggle from './ThemeToggle'
import { FeatureFlags } from '../utils/featureFlags'
import Link from './Link'
import { FiMenu } from '@react-icons/all-files/fi/FiMenu'
import { MdClose } from '@react-icons/all-files/md/MdClose'

export default function Navbar() {
    const [modalIsOpen, setIsOpen] = React.useState(false)

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }
    Modal.setAppElement('#___gatsby')

    function openModal() {
        setIsOpen(true)
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false)
    }

    return (
        <header>
            <nav>
                <h1>
                    <Link to="/">drazen o. glava</Link>
                </h1>
                <div className="links">
                    <Link to="/">home</Link>
                    {FeatureFlags?.showAboutMenuItem && <Link to="/about">about</Link>}
                    <Link
                        activeClassName="partially-active"
                        partiallyActive={true}
                        to="/projects"
                    >
                        projects
                    </Link>
                    <Link
                        activeClassName="partially-active"
                        partiallyActive={true}
                        to="/blogs"
                    >
                        logs
                    </Link>
                    {FeatureFlags?.showContactMenuItem && <Link to="/contact">contact</Link>}
                </div>
                <button
                    aria-label="mobile-menu button"
                    className="menu"
                    type="button"
                    onClick={openModal}
                >
                    <FiMenu />
                </button>
                <ThemeToggle />
            </nav>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="mobile menu"
            >
                <div className="close-btn-container">
                    <button
                        aria-label="mobile-menu close button"
                        className="menu-close"
                        type="button"
                        onClick={closeModal}
                    >
                        <MdClose />
                    </button>
                </div>
                <nav>
                    <div
                        className="links"
                        onClick={closeModal}
                    >
                        <Link to="/">home</Link>
                        {FeatureFlags?.showAboutMenuItem && <Link to="/about">about</Link>}
                        <Link
                            activeClassName="partially-active"
                            partiallyActive={true}
                            to="/projects"
                        >
                            projects
                        </Link>
                        <Link
                            activeClassName="partially-active"
                            partiallyActive={true}
                            to="/blogs"
                        >
                            logs
                        </Link>
                        {FeatureFlags?.showContactMenuItem && <Link to="/contact">contact</Link>}
                    </div>
                    <hr />
                    <ThemeToggle />
                </nav>
            </Modal>
        </header>
    )
}
