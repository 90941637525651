import React, { Dispatch, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react'
import Toggle from 'react-toggle'
import { GlobalStateContext, GlobalStateContextType } from '../context/GlobalContextProvider'
import { addThemeListener, getTheme, removeThemeListener, setPreferredTheme } from '../utils'
import '../styles/themeToggle.scss'
import { FaMoon } from '@react-icons/all-files/fa/FaMoon'
import { FiSun } from '@react-icons/all-files/fi/FiSun'

const ICONS = {
    checked: <FaMoon />,
    unchecked: <FiSun />,
}

const ThemeToggle = () => {
    const state: GlobalStateContextType = useContext(GlobalStateContext)
    const [checked, setChecked] = useState(!state.isDarkTheme)
    const [hasMounted, setHasMounted] = useState(false)

    useLayoutEffect(() => {
        setHasMounted(true)
    }, [])

    const onChange = useCallback(
        (e: { target: { checked: boolean } }) => {
            const isChecked = e.target.checked
            setChecked(isChecked)
            state.toggleTheme({ type: 'TOGGLE_THEME' })
            setPreferredTheme(isChecked ? 'light-theme' : 'dark-theme')
        },
        [setChecked]
    )

    useEffect(() => {
        setChecked(!state.isDarkTheme)
    }, [state.isDarkTheme])

    useEffect(() => {
        addThemeListener(handleThemeChange)

        return () => {
            removeThemeListener(handleThemeChange)
        }
    }, [])

    const handleThemeChange = useCallback(() => {
        setChecked(getTheme() === 'light-theme')
    }, [])

    if (!hasMounted) {
        return null
    }

    return (
        <Toggle
            aria-label="Toggle between light and dark theme"
            name="theme-toggle"
            checked={checked}
            icons={ICONS}
            onChange={onChange}
        />
    )
}

export default ThemeToggle
