import React from 'react'
import GitInfo from 'react-git-info/macro'
import { FiGithub } from '@react-icons/all-files/fi/FiGithub'
import { FaGithub } from '@react-icons/all-files/fa/FaGithub'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { FaAt } from '@react-icons/all-files/fa/FaAt'
import { GoGitCommit } from '@react-icons/all-files/go/GoGitCommit'
import { FaRegCopyright } from '@react-icons/all-files/fa/FaRegCopyright'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { FeatureFlags } from '../utils'

type FooterDataQlType = {
    title: string
    description: string
    author: string
    pro: string
    location: string
    contact: string
    copyright: string
    gconfig: string
}

export default function Footer(): JSX.Element {
    const gitInfo = GitInfo()
    const { copyright, contact }: FooterDataQlType = useSiteMetadata()

    return (
        <footer>
            <div className="container">
                <div className="foo-row social">
                    <a
                        aria-label="Twitter profile link"
                        href="https://twitter.com/glava11"
                        target="_blank"
                    >
                        <FaTwitter />
                    </a>
                    <a
                        aria-label="Github profile link"
                        href="https://github.com/glava11"
                        target="_blank"
                    >
                        <FaGithub />
                    </a>
                    <a
                        aria-label="Linkedin profile link"
                        href="https://linkedin.com/in/drazenorsolic"
                        target="_blank"
                    >
                        <FaLinkedinIn />
                    </a>
                    {FeatureFlags?.showCvCTA && (
                        <a
                            aria-label="Email link"
                            href={`mailto:${contact}?subject=Hi via website&body=...`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaAt />
                        </a>
                    )}
                </div>
            </div>
            <div className="container">
                <div className="foo-row">
                    <FaRegCopyright /> {copyright}
                </div>
            </div>
            <div className="container">
                <div
                    className="foo-row"
                    title={`built at ${gitInfo.commit.date}`}
                >
                    <span>
                        <FiGithub />{' '}
                        {gitInfo.tags
                            ? gitInfo.tags.length > 1
                                ? gitInfo.tags[gitInfo.tags.length - 1]
                                : gitInfo.tags
                            : 'v1.X.X'}{' '}
                        |{' '}
                    </span>
                    <span>
                        <GoGitCommit /> {gitInfo.commit.shortHash}
                    </span>
                </div>
            </div>
        </footer>
    )
}
