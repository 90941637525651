import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        pro
                        location
                        contact
                        contactString
                        copyright
                        gconfig
                        twitterUsername
                        image
                        siteUrl
                    }
                }
            }
        `
    )
    return site.siteMetadata
}
